import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
// import HeaderSimple from 'components/HeaderSimple'
import TextImage from 'components/TextImage'
import CollectionSlider from 'components/CollectionSlider'

import s from './category-summary.module.css'

const CategorySummary = ({ data }) => {
  const {
    seo: { title: seoTitle, description: seoDescription },
    // categorySummaryHeader: header,
    acfCategorySummary: { blocks },
  } = data.wordpressPage
  return (
    <Layout>
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO title={seoTitle} description={seoDescription} />
      {/* <HeaderSimple className={s.header} data={header} /> */}
      {blocks?.map(
        (
          {
            fieldGroupName,
            categorySummaryTextImage,
            style,
            title,
            description,
            collectionSlider,
          },
          i
        ) => {
          return (
            <Fragment key={`${fieldGroupName}${i}`}>
              {fieldGroupName ===
                'page_Acfcategorysummary_Blocks_TextImage' && (
                <div className={s.textImageWrapper}>
                  <TextImage
                    className={s.textImage}
                    data={categorySummaryTextImage}
                    style={style}
                    noPageLink
                  />
                </div>
              )}
              {fieldGroupName ===
                'page_Acfcategorysummary_Blocks_CollectionSlider' && (
                <CollectionSlider
                  title={title}
                  description={description}
                  data={collectionSlider}
                />
              )}
            </Fragment>
          )
        }
      )}
    </Layout>
  )
}

export default CategorySummary

CategorySummary.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      acfCategorySummary: PropTypes.shape({
        // categorySummaryHeader: PropTypes.object,
        blocks: PropTypes.arrayOf(
          PropTypes.shape({
            fieldGroupName: PropTypes.string,
            style: PropTypes.string,
            categorySummaryTextImage: PropTypes.array,
            collectionSlider: PropTypes.array,
          })
        ),
      }),
    }),
  }),
}

export const pageQuery = graphql`
  query CategorySummaryByID($id: String!) {
    wordpressPage(id: { eq: $id }) {
      seo {
        title
        description
      }
      acfCategorySummary {
        categorySummaryHeader {
          introduction
          linkType
          collectionHandle
          productHandle
          linkLabel
        }
        blocks {
          ... on WordpressPage_Acfcategorysummary_Blocks_TextImage {
            fieldGroupName
            style
            categorySummaryTextImage {
              title
              description
              linkType
              collectionHandle
              productHandle
              linkLabel
              images {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800, placeholder: NONE)
                    }
                  }
                }
              }
            }
          }
          ... on WordpressPage_Acfcategorysummary_Blocks_CollectionSlider {
            fieldGroupName
            title
            description
            collectionSlider {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 310
                      placeholder: NONE
                      aspectRatio: 1
                      sizes: "(max-width: 900px) 150px, (max-width: 6000px) 310px"
                      breakpoints: [900]
                    )
                  }
                }
              }
              collectionHandle
              linkLabel
              title
            }
          }
        }
      }
    }
  }
`

// title
// description
// link {
//   target
//   title
//   url
// }
