import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import LinkWordPress from 'components/LinkWordPress'

import s from './styles.module.css'

const CollectionSlider = ({ title, description, link, data, className }) => {
  if (data?.length < 1) return null
  return (
    <div className={classNames(s.container, className)}>
      {title && (
        <h2 className={s.heading} dangerouslySetInnerHTML={{ __html: title }} />
      )}
      {description && (
        <div
          className={s.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      <div className={s.wrapper}>
        {data.map(
          (
            {
              image,
              linkType,
              collectionHandle,
              productHandle,
              title,
              linkLabel,
            },
            i
          ) => {
            if (!image?.localFile) return null
            return (
              <div key={`${image?.localFile?.id}${i}`} className={s.slide}>
                <GatsbyImage
                  className={s.img}
                  image={getImage(image.localFile)}
                  alt=""
                  backgroundColor="#eee"
                />
                <div className={s.details}>
                  <LinkWordPress
                    className={s.link}
                    linkType={linkType}
                    collectionHandle={collectionHandle}
                    productHandle={productHandle}
                    noPageLink
                  />
                  <h2
                    className={s.title}
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                  <span
                    className={s.label}
                    dangerouslySetInnerHTML={{ __html: linkLabel }}
                  />
                </div>
              </div>
            )
          }
        )}
      </div>
      <LinkWordPress className={s.linkMain} link={link} />
    </div>
  )
}

CollectionSlider.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.shape({
    target: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      linkType: PropTypes.string,
      collectionHandle: PropTypes.string,
      productHandle: PropTypes.string,
      title: PropTypes.string,
      linkLabel: PropTypes.string,
    })
  ),
  className: PropTypes.string,
}
CollectionSlider.defaultProps = {
  title: '',
  description: '',
  link: {},
  data: {},
  className: '',
}

export default CollectionSlider
